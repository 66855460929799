import React from 'react';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';

import MyEuiPageContent from '../../components/MyEuiPageContent';
import GroupView from './GroupView';
import AddLink from './AddLink';

const Links = ({ user, title }) => {
    return (
        <MyEuiPageContent title={title} >
            <EuiFlexGroup>
                <EuiFlexItem grow={3} >
                    <AddLink user={user} />
                </EuiFlexItem>
                <EuiFlexItem grow={7} >
                    <GroupView user={user} />
                </EuiFlexItem>
            </EuiFlexGroup>
        </MyEuiPageContent>
    )
}

export default Links
