import React, { useState } from 'react';
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiFilePicker,
    EuiImage,
    EuiHorizontalRule,
} from '@elastic/eui';

import { auth, storage, firebase } from "../../../helpers/firebase"
import MyEuiPageContent from '../../../components/MyEuiPageContent';
import ChangePassword from './ChangePassword';

const UserSettings = ({ user, title }) => {
    const [loading, setLoading] = useState(false)
    const [displayName, setDisplayName] = useState(user.displayName ? user.displayName : "")
    const [pictureFile, setPictureFile] = useState(null)
    const [picturePreview, setPicturePreview] = useState(null)

    const updateProfile = async () => {
        setLoading(true)

        const data = { displayName }

        const complete = async () => {
            await user.updateProfile(data)

            await auth.updateCurrentUser(user)

            setLoading(false)
        }

        if (pictureFile) {
            const ref = storage.ref().child(`/userImages/${user.uid}`)

            const uploadTask = ref.put(pictureFile)

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
                // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            }, error => {
                console.log(error)
            }, async () => {
                const downloadURL = await ref.getDownloadURL()

                data.photoURL = downloadURL

                complete()
            })
        } else {
            complete()
        }
    }

    const handlePictureChange = file => {
        setPictureFile(file)

        if (file) {
            const reader = new FileReader()

            reader.onload = (e => setPicturePreview(e.target.result))

            reader.readAsDataURL(file)
        } else {
            setPicturePreview(null)
        }
    }

    return (
        <MyEuiPageContent title={title} >
            <EuiForm>
                <EuiFormRow label="Display name" >
                    <EuiFieldText
                        name="displayName"
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                    />
                </EuiFormRow>

                {(user.photoURL || picturePreview) && (
                    <EuiFormRow label="Picture" >
                        <EuiImage
                            size="m"
                            hasShadow
                            allowFullScreen
                            alt="User image"
                            url={picturePreview ? picturePreview : user.photoURL}
                        />
                    </EuiFormRow>
                )}

                <EuiFormRow>
                    <EuiFilePicker
                        id="picture"
                        initialPromptText="Select or drag and drop picture"
                        onChange={files => handlePictureChange(files[0])}
                        accept="image/*"
                    />
                </EuiFormRow>

                <EuiSpacer />

                <EuiButton
                    type="submit"
                    fill
                    isLoading={loading}
                    onClick={updateProfile}
                >
                    Save
                </EuiButton>
            </EuiForm>

            <EuiHorizontalRule />

            <ChangePassword />
        </MyEuiPageContent>
    )
}

export default UserSettings
