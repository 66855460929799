import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
    EuiButton,
    EuiContextMenu,
    EuiIcon,
    EuiPopover,
} from '@elastic/eui';

import { auth } from "../../helpers/firebase"

const flattenPanelTree = (tree, array = []) => {
    array.push(tree);

    if (tree.items) {
        tree.items.forEach(item => {
            if (item.panel) {
                flattenPanelTree(item.panel, array);
                item.panel = item.panel.id;
            }
        });
    }

    return array;
}

const UserMenu = ({ user }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const history = useHistory()

    const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

    const isLeftClickEvent = (event) => event.button === 0

    const onClick = (event, to) => {
        if (event.defaultPrevented) {
            return
        }

        if (event.target.getAttribute('target')) {
            return
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
            return
        }

        event.preventDefault()

        history.push(to)
    }

    const panelTree = {
        id: 0,
        title: `Signed in as ${user.email}`,
        items: [
            {
                name: 'Settings',
                icon: <EuiIcon type="gear" size="m" />,
                onClick: e => {
                    setIsPopoverOpen(false)
                    onClick(e, "/user/settings")
                },
            },
            {
                name: 'Sign out',
                icon: <EuiIcon type="exit" size="m" />,
                onClick: () => {
                    setIsPopoverOpen(false)
                    auth.signOut()
                },
            },
            // {
            //     name: 'Go to a link',
            //     icon: 'user',
            //     href: 'http://elastic.co',
            //     target: '_blank',
            // },
            // {
            //     name: 'Nest panels',
            //     icon: 'user',
            //     panel: {
            //         id: 1,
            //         title: 'Nest panels',
            //         items: [
            //             {
            //                 name: 'PDF reports',
            //                 icon: 'user',
            //                 onClick: () => {
            //                     this.closePopover();
            //                     window.alert('PDF reports');
            //                 },
            //             },
            //             {
            //                 name: 'Permalinks',
            //                 icon: 'user',
            //                 onClick: () => {
            //                     this.closePopover();
            //                     window.alert('Permalinks');
            //                 },
            //             },
            //         ],
            //     },
            // },
            // {
            //     name: 'You can add a tooltip',
            //     icon: 'user',
            //     toolTipTitle: 'Optional tooltip',
            //     toolTipContent: 'Optional content for a tooltip',
            //     toolTipPosition: 'right',
            //     onClick: () => {
            //         this.closePopover();
            //         window.alert('Display options');
            //     },
            // },
            // {
            //     name: 'Disabled option',
            //     icon: 'user',
            //     toolTipContent: 'For reasons, this item is disabled',
            //     toolTipPosition: 'right',
            //     disabled: true,
            //     onClick: () => {
            //         this.closePopover();
            //         window.alert('Disabled option');
            //     },
            // },
        ],
    }

    const panels = flattenPanelTree(panelTree)

    const button = (
        <EuiButton
            iconType="arrowDown"
            iconSide="right"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
            {user.displayName ? user.displayName : user.email}
        </EuiButton>
    );

    return (
        <EuiPopover
            id="contextMenu"
            button={button}
            isOpen={isPopoverOpen}
            closePopover={() => setIsPopoverOpen(false)}
            panelPaddingSize="none"
            withTitle
            anchorPosition="downLeft"
        >
            <EuiContextMenu initialPanelId={0} panels={panels} />
        </EuiPopover>
    );
}

export default UserMenu
