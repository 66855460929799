import React, { Fragment, useState } from 'react';
import {
    EuiButton,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
} from '@elastic/eui';

import { auth } from "../../helpers/firebase"

const Register = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])

    const register = () => {
        setLoading(true)
        setErrors([])

        auth.createUserWithEmailAndPassword(email, password).catch(e => {
            console.log(e)
            setErrors([...errors, e.message])
            setLoading(false)
        })
    }

    const formValid = () => email && password && password2 && password === password2

    const checkPassword = () => password !== password2 && setErrors([...errors, "Passwords don't match"])

    const resetError = () => setErrors([])

    const isPasswordError = errors.some(error => error === "Passwords don't match" || error === "Password should be at least 6 characters")

    const isEmailError = errors.some(error => error === "The email address is badly formatted." || error === "The email address is already in use by another account.")

    return (
        <Fragment>
            <EuiForm isInvalid={errors.length > 0} error={errors} >
                <EuiFormRow label="Email" isInvalid={isEmailError} >
                    <EuiFieldText
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onFocus={resetError}
                    />
                </EuiFormRow>

                <EuiFormRow label="Password" isInvalid={isPasswordError} >
                    <EuiFieldPassword
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onFocus={resetError}
                    />
                </EuiFormRow>

                <EuiFormRow label="Repeat password" isInvalid={isPasswordError} >
                    <EuiFieldPassword
                        name="password"
                        value={password2}
                        onChange={e => setPassword2(e.target.value)}
                        onBlur={checkPassword}
                        onFocus={resetError}
                    />
                </EuiFormRow>

                <EuiSpacer />

                <EuiButton
                    type="submit"
                    fill
                    disabled={loading || !formValid()}
                    isLoading={loading}
                    onClick={register}
                >
                    Register
                </EuiButton>
            </EuiForm>
            {/* <Header as="h2" content="Register" textAlign="center" />
            <Segment basic >
                <Form loading={loading} error={error !== null}  >
                    <Form.Input
                        required
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(_, { value }) => setEmail(value)}
                        onFocus={resetError}
                    />
                    <Form.Input
                        required
                        type="password"
                        label="Password"
                        value={password}
                        onChange={(_, { value }) => setPassword(value)}
                        onFocus={resetError}
                    />
                    <Form.Input
                        required
                        type="password"
                        label="Repeat password"
                        error={error && error.message === "Passwords don't match"}
                        value={password2}
                        onChange={(_, { value }) => setPassword2(value)}
                        onBlur={checkPassword}
                        onFocus={resetError}
                    />
                    <Message
                        error
                        content={error && error.message}
                    />
                    <Message
                        success
                        content="Registered!"
                    />
                    <Form.Button
                        secondary
                        fluid
                        content="Register"
                        disabled={!formValid()}
                        onClick={register}
                    />
                </Form>
            </Segment> */}
        </Fragment>
    )
}

export default Register;