import React from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiSpacer,
} from '@elastic/eui';

import MyEuiPageContent from '../../components/MyEuiPageContent';

import LoginView from './Login';
import Register from './Register';

const Login = ({ shouldRedirect = true }) => {
    return (
        <MyEuiPageContent title="Sign in to djmoberg.com" >
            <EuiFlexGroup alignItems="center" >
                <EuiFlexItem>
                    <LoginView shouldRedirect={shouldRedirect} />
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiTitle>
                        <h2>Register</h2>
                    </EuiTitle>
                    <EuiSpacer />
                    <Register />
                </EuiFlexItem>
            </EuiFlexGroup>
        </MyEuiPageContent>
    );

}

export default Login;