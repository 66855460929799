const key = "toasts"

export const getToasts = () => {
    const toastsStorage = localStorage.getItem(key)

    if (toastsStorage) return JSON.parse(toastsStorage)

    return []
}

export const setToasts = toasts => localStorage.setItem(key, JSON.stringify(toasts))

export const addToast = toast => {
    const toasts = getToasts()

    let id = "1"

    if (toasts.length > 0) id = `${parseInt(toasts[toasts.length - 1].id) + 1}`

    toasts.push({ id, ...toast })

    setToasts(toasts)
}

export const removeToast = id => {
    const toasts = getToasts()

    const _toasts = toasts.filter(toast => toast.id !== id)

    setToasts(_toasts)
}