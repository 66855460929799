import React, { Fragment, useState, useEffect } from 'react';
import {
    EuiListGroup,
    EuiListGroupItem,
    EuiTitle,
    EuiTextColor,
} from '@elastic/eui';

import { db } from '../../helpers/firebase'
import { addToast } from '../../helpers/toasts';

const NotesView = ({ user, onNoteClick }) => {
    const [notes, setNotes] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)

    const unsubscribe = db.collection("users").doc(user.uid).collection("notes").onSnapshot((snap) => {
        const docs = snap.docs

        const notes = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        setNotes(notes)
    });

    useEffect(() => unsubscribe)

    const handleDeleteNote = async ({ id, ...note }) => {
        setIsDeleting(true)
        await db.collection("users").doc(user.uid).collection("notes").doc(id).delete()
        setIsDeleting(false)

        addToast({
            type: 'undo',
            collection: "notes",
            docId: id,
            data: note,
            title: 'Note deleted',
        })
    }

    return (
        <Fragment>
            {notes && (
                <EuiListGroup flush >
                    {notes.map(note => {
                        const isEmpty = (!note.title || note.title.length <= 0) && (!note.note || note.note.length <= 0)

                        return (
                            <Fragment key={note.id} >
                                <EuiListGroupItem
                                    // wrapText
                                    label={
                                        <Fragment>
                                            <EuiTitle>
                                                <h2>{isEmpty ? "---" : note.title}</h2>
                                            </EuiTitle>
                                            <p><EuiTextColor color="subdued" >{isEmpty ? "(Empty)" : note.note}</EuiTextColor></p>
                                        </Fragment>
                                    }
                                    onClick={() => onNoteClick(note)}
                                    extraAction={{
                                        color: 'subdued',
                                        onClick: () => handleDeleteNote(note),
                                        iconType: 'trash',
                                        iconSize: 's',
                                        alwaysShow: false,
                                        'aria-label': `Delete ${note.title}`,
                                        disabled: { isDeleting }
                                    }}
                                />
                            </Fragment>
                        )
                    })}
                </EuiListGroup>
            )}
        </Fragment>
    );
}

export default NotesView;
