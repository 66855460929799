import React, { useState } from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiSelect,
} from '@elastic/eui';

import { db } from '../../helpers/firebase'

const LinkEditModal = ({ user, groups, setIsModalVisible, link, onDeleteLink }) => {
    const [name, setName] = useState(link.name)
    const [url, setUrl] = useState(link.url)
    const [group, setGroup] = useState(link.group)
    const [tempGroup, setTempGroup] = useState("")
    const [newGroupMode, setNewGroupMode] = useState(false)
    const [saving, setSaving] = useState(false)

    const closeModal = () => setIsModalVisible(false)

    const handleDeleteLink = () => {
        onDeleteLink(link)

        closeModal()
    }

    const save = async () => {
        setSaving(true)

        const isNewGroup = group === "temp"

        const groupDocRef = isNewGroup ?
            db.collection("users").doc(user.uid).collection("groups").doc()
            :
            db.collection("users").doc(user.uid).collection("groups").doc(group)

        if (isNewGroup) await groupDocRef.set({ name: tempGroup })

        await db.collection("users").doc(user.uid).collection("links").doc(link.id).update({ name, url, group: groupDocRef.id })

        setSaving(false)
        closeModal()
    }

    const getOptions = () => {
        const options = []

        const groupOptions = groups.map(group => ({ key: group.id, text: group.name, value: group.id }))

        return [...options, ...groupOptions]
    }

    const options = getOptions()

    const formIsValid = () => name && url && group.length > 0

    const handleNewGroupMode = () => {
        setGroup("temp")
        setNewGroupMode(true)
    }

    const handleSelectMode = () => {
        setGroup(link.group ? link.group : groups[0].id)
        setNewGroupMode(false)
    }

    return (
        <EuiOverlayMask>
            <EuiModal onClose={closeModal} initialFocus="[name=name]">
                <EuiModalHeader>
                    <EuiModalHeaderTitle>Edit {link.name}</EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <EuiForm>
                        <EuiFormRow label="Name">
                            <EuiFieldText
                                name="name"
                                placeholder={link.name}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </EuiFormRow>

                        <EuiFormRow label="Url">
                            <EuiFieldText
                                name="url"
                                placeholder={link.url}
                                value={url}
                                onChange={e => setUrl(e.target.value)}
                            />
                        </EuiFormRow>

                        <EuiFormRow label="Group">
                            {newGroupMode ?
                                <EuiFieldText
                                    name="group"
                                    value={tempGroup}
                                    onChange={e => setTempGroup(e.target.value)}
                                    prepend={<EuiButton onClick={handleSelectMode} >Select</EuiButton>}
                                />
                                :
                                <EuiSelect
                                    options={options}
                                    value={group}
                                    onChange={e => setGroup(e.target.value)}
                                    prepend={<EuiButton onClick={handleNewGroupMode} >New</EuiButton>}
                                />
                            }
                        </EuiFormRow>

                    </EuiForm>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty isDisabled={saving} onClick={handleDeleteLink} color="danger" >Delete</EuiButtonEmpty>

                    <EuiButtonEmpty isDisabled={saving} onClick={closeModal}>Cancel</EuiButtonEmpty>

                    <EuiButton
                        fill
                        disabled={!formIsValid()}
                        onClick={save}
                        isLoading={saving}
                        isDisabled={saving}
                    >
                        {saving ? "Saving..." : "Save"}
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    )
}

export default LinkEditModal
