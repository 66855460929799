import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyC7qk32ePBMd-Rkdx2H4860thh6Jjkl620",
    authDomain: "djmoberg-7f817.firebaseapp.com",
    databaseURL: "https://djmoberg-7f817.firebaseio.com",
    projectId: "djmoberg-7f817",
    storageBucket: "djmoberg-7f817.appspot.com",
    messagingSenderId: "192641421254",
    appId: "1:192641421254:web:7ff9206e161d47f5"
});

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

export { firebase, db, auth, storage };