import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCOMAcZvfYQgQDmtodJSOPQKHmakEzGTw8",
    authDomain: "project-tracker-c2c94.firebaseapp.com",
    databaseURL: "https://project-tracker-c2c94.firebaseio.com",
    projectId: "project-tracker-c2c94",
    storageBucket: "project-tracker-c2c94.appspot.com",
    messagingSenderId: "355304899810",
    appId: "1:355304899810:web:e400924b2c35b7d23ecabb"
}, "secondary");

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

export { firebase, db, auth, storage };