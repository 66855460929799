import React, { useState } from 'react';

import { auth } from "../../helpers/firebase"
import EuiLinkButton from '../EuiLinkButton';
import UserMenu from './UserMenu';

const AuthStatus = () => {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    auth.onAuthStateChanged(user => {
        setLoading(false)
        setUser(user)
    })

    if (loading)
        return <p></p>

    if (user)
        return <UserMenu user={user} />

    return (
        <EuiLinkButton to="/login" >
            Sign in
        </EuiLinkButton>
    )
}

export default AuthStatus
