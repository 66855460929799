import React from 'react'
import { useHistory } from 'react-router';
import {
    EuiCard,
    EuiIcon,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';

const ProjectView = ({ user, match: { params: { id } } }) => {
    const history = useHistory()

    const handleClick = to => history.push(`/projectTracker/projects/${id}/${to}`)

    return (
        <EuiFlexGroup gutterSize="l">
            <EuiFlexItem>
                <EuiCard
                    icon={<EuiIcon size="xxl" type="clock" />}
                    title="Work Timer"
                    description="Clock in and out."
                    onClick={() => handleClick("workTimer")}
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiCard
                    icon={<EuiIcon size="xxl" type="calendar" />}
                    title="Overview"
                    description="Overview of the work done on this project."
                    onClick={() => handleClick("overview")}
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiCard
                    icon={<EuiIcon size="xxl" type="listAdd" />}
                    title="Add work"
                    description="Add work done on this project."
                    onClick={() => handleClick("addWork")}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}

export default ProjectView
