import React, { useState } from 'react';
import {
    EuiButton,
    EuiSpacer,
} from '@elastic/eui';

import MyEuiPageContent from '../../components/MyEuiPageContent';
import AddReminder from './AddReminder'
import RemindersView from './RemindersView'

const WhenDidI = ({ user }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    return (
        <MyEuiPageContent title="When Did I?" >
            <RemindersView user={user} />

            <EuiSpacer size="xxl" />

            <EuiButton onClick={() => setIsModalVisible(true)} >Add</EuiButton>

            {isModalVisible && <AddReminder user={user} closeModal={() => setIsModalVisible(false)} />}
        </MyEuiPageContent>
    );
}

export default WhenDidI;
