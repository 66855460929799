import React, { useState, useEffect } from 'react';
import {
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiButton,
    EuiSelect,
} from '@elastic/eui';

import { db } from '../../helpers/firebase'

const AddLink = ({ user }) => {
    const [name, setName] = useState("")
    const [url, setUrl] = useState("")
    const [group, setGroup] = useState("")
    const [tempGroup, setTempGroup] = useState("")
    const [groups, setGroups] = useState([])
    const [newGroupMode, setNewGroupMode] = useState(false)

    const unsubscribe = db.collection("users").doc(user.uid).collection("groups").onSnapshot((snap) => {
        const docs = snap.docs

        const groups = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        setGroups(groups)
        if (group.length <= 0) setGroup(groups[0].id)
    });

    useEffect(() => unsubscribe)

    const add = async () => {
        // setSaving(true)

        const isNewGroup = group === "temp"

        const groupDocRef = isNewGroup ?
            db.collection("users").doc(user.uid).collection("groups").doc()
            :
            db.collection("users").doc(user.uid).collection("groups").doc(group)

        if (isNewGroup) await groupDocRef.set({ name: tempGroup })

        await db.collection("users").doc(user.uid).collection("links").doc().set({ name, url, group: groupDocRef.id })

        setName("")
        setUrl("")
        setGroup(groups[0].id)
        setTempGroup("")
        // setSaving(false)
    }

    const getOptions = () => {
        const options = []

        const groupOptions = groups.map(group => ({ key: group.id, text: group.name, value: group.id }))

        return [...options, ...groupOptions]
    }

    const options = getOptions()

    const formIsValid = () => name && url && group.length > 0

    const handleNewGroupMode = () => {
        setGroup("temp")
        setNewGroupMode(true)
    }

    const handleSelectMode = () => {
        setGroup(groups[0].id)
        setNewGroupMode(false)
    }

    return (
        <EuiForm>
            <EuiFormRow label="Name">
                <EuiFieldText
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </EuiFormRow>

            <EuiFormRow label="Url">
                <EuiFieldText
                    name="url"
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                />
            </EuiFormRow>

            <EuiFormRow label="Group">
                {newGroupMode ?
                    <EuiFieldText
                        name="group"
                        value={tempGroup}
                        onChange={e => setTempGroup(e.target.value)}
                        prepend={<EuiButton onClick={handleSelectMode} >Select</EuiButton>}
                    />
                    :
                    <EuiSelect
                        options={options}
                        value={group}
                        onChange={e => setGroup(e.target.value)}
                        prepend={<EuiButton onClick={handleNewGroupMode} >New</EuiButton>}
                    />
                }
            </EuiFormRow>

            <EuiButton
                type="submit"
                fill
                disabled={!formIsValid()}
                onClick={add}
            >
                Add
            </EuiButton>
        </EuiForm>
        // <Form>
        //     <Form.Input label="Name" value={name} onChange={e => setName(e.target.value)} ></Form.Input>
        //     <Form.Input label="Url" value={url} onChange={e => setUrl(e.target.value)} ></Form.Input>
        //     <Form.Dropdown
        //         selection
        //         search
        //         label="Group"
        //         placeholder="Select Group"
        //         options={options}
        //         value={group}
        //         onChange={(_, { value }) => setGroup(value)}
        //         allowAdditions
        //         onAddItem={handleAddition}
        //     />
        //     <Form.Button onClick={add} floated="right" disabled={!formIsValid()} >Add</Form.Button>
        // </Form>
    );
}

export default AddLink;
