import React, { useState } from 'react'
import { EuiButton, EuiLoadingContent } from '@elastic/eui';
import { Route, Switch, Redirect, useHistory } from "react-router-dom"

import MyEuiPageContent from '../../components/MyEuiPageContent';
import Login from './Login';

import { auth } from "../../helpers/firebaseSecondary"
import ProjectPicker from './ProjectPicker';
import ProjectView from './ProjectView';
import Overview from './ProjectView/Overview';
import WorkTimer from './ProjectView/WorkTimer';
import AddWork from './ProjectView/AddWork';

const ProjectTracker = ({ match: { url } }) => {
    const history = useHistory()

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    auth.onAuthStateChanged(user => {
        setUser(user)
        setLoading(false)
    })

    if (loading) return (
        <MyEuiPageContent title="Project Tracker" >
            <EuiLoadingContent lines={3} />
        </MyEuiPageContent>
    )

    if (!user && history.location.pathname !== url) return <Redirect to={url} />

    if (!user) return <Login />

    const getProjectName = () => {
        const savedProjects = localStorage.getItem("projectTrackerProjects")

        if (savedProjects) {
            try {
                const pathArray = history.location.pathname.split("/")
                const projects = JSON.parse(savedProjects)
                const project = projects.find(project => pathArray[3] === project.id)

                return project ? project.name : null
            } catch (error) {
                return null
            }
        }

        return null
    }

    const projectName = getProjectName()

    return (
        <MyEuiPageContent
            responsive={false}
            title={projectName ? `${projectName}` : "Project Tracker"}
            contentAbilities={!projectName ? <EuiButton onClick={() => auth.signOut()} >Sign out</EuiButton> : null}
        >
            <Switch>
                <Route path={url + "/projects"} exact component={props => ProjectPicker({ user, ...props })} />

                <Route path={url + "/projects/:id"} exact component={props => ProjectView({ user, ...props })} />
                <Route path={url + "/projects/:id/overview"} exact component={props => Overview({ user, ...props })} />
                <Route path={url + "/projects/:id/workTimer"} exact component={props => WorkTimer({ user, ...props })} />
                <Route path={url + "/projects/:id/addWork"} exact component={props => AddWork({ user, ...props })} />

                <Redirect to={url + "/projects"} />
            </Switch>
        </MyEuiPageContent>
    )
}

export default ProjectTracker
