import React, { Fragment, useState, useEffect } from 'react';
import {
    EuiBasicTable,
    EuiDescriptionList,
    EuiButtonIcon,
} from '@elastic/eui';
import { RIGHT_ALIGNMENT } from '@elastic/eui/lib/services';

import { db } from '../../helpers/firebase'
import { addToast } from '../../helpers/toasts';
import AddReminder from './AddReminder';

const RemindersView = ({ user }) => {
    const [reminders, setReminders] = useState(null)
    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({})
    const [editReminder, setEditReminder] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)

    const unsubscribe = db.collection("users").doc(user.uid).collection("reminders").onSnapshot((snap) => {
        const docs = snap.docs

        const _reminders = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        if (JSON.stringify(_reminders) !== JSON.stringify(reminders)) setReminders(_reminders)
    });

    useEffect(() => unsubscribe)

    const toggleDetails = reminder => {
        const _itemIdToExpandedRowMap = { ...itemIdToExpandedRowMap };

        if (_itemIdToExpandedRowMap[reminder.id]) {
            delete _itemIdToExpandedRowMap[reminder.id];
        } else {
            const listItems = [
                {
                    title: "Log",
                    description: <p style={{ whiteSpace: "pre" }} >{reminder.comment}</p>
                }
            ]

            _itemIdToExpandedRowMap[reminder.id] = <EuiDescriptionList listItems={listItems} />
        }

        setItemIdToExpandedRowMap(_itemIdToExpandedRowMap);
    }

    const handleDelete = async ({ id, ...reminder }) => {
        if (!isDeleting) {
            setIsDeleting(true)
            await db.collection("users").doc(user.uid).collection("reminders").doc(id).delete()
            setIsDeleting(false)

            addToast({
                type: 'undo',
                collection: "reminders",
                docId: id,
                data: reminder,
                title: 'Reminder deleted',
            })
        }
    }

    const columns = [
        {
            field: 'name',
            name: 'Name',
        },
        {
            name: 'Actions',
            actions: [
                {
                    name: 'Delete',
                    description: 'Delete this reminder',
                    icon: 'trash',
                    color: 'danger',
                    type: 'icon',
                    onClick: handleDelete,
                    isPrimary: true,
                    'data-test-subj': 'action-delete',
                },
                {
                    name: 'Edit',
                    isPrimary: true,
                    description: 'Edit this reminder',
                    icon: 'pencil',
                    type: 'icon',
                    onClick: setEditReminder,
                    'data-test-subj': 'action-edit',
                },
            ],
        },
        {
            align: RIGHT_ALIGNMENT,
            width: '40px',
            isExpander: true,
            render: item => (
                <EuiButtonIcon
                    onClick={() => toggleDetails(item)}
                    aria-label={itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'}
                    iconType={itemIdToExpandedRowMap[item.id] ? 'arrowUp' : 'arrowDown'}
                />
            ),
        },
    ];

    const items = reminders ? reminders : []

    return (
        <Fragment>
            <EuiBasicTable
                items={items}
                itemId="id"
                columns={columns}
                itemIdToExpandedRowMap={itemIdToExpandedRowMap}
                hasActions
            />
            {editReminder && <AddReminder user={user} reminder={editReminder} closeModal={() => setEditReminder(null)} />}
        </Fragment>
    );
}

export default RemindersView;