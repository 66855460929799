import React, { useState } from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
} from '@elastic/eui';

import { db } from '../../helpers/firebase'

const AddReminder = ({ user, closeModal, reminder }) => {
    const [name, setName] = useState(reminder ? reminder.name : "")
    const [saving, setSaving] = useState(false)

    const isEdit = reminder

    const handleSave = async () => {
        setSaving(true)

        const collectionRef = db.collection("users").doc(user.uid).collection("reminders")

        if (isEdit) await collectionRef.doc(reminder.id).update({ name })
        else await collectionRef.doc().set({ name })

        setSaving(false)
        closeModal()
    }

    const isValid = name.length > 0

    const handleKeyDown = e => {
        if (e.key === 'Enter' && isValid) {
            handleSave()
        }
    }

    return (
        <EuiOverlayMask>
            <EuiModal onClose={closeModal} initialFocus="[name=name]">
                <EuiModalHeader>
                    <EuiModalHeaderTitle>{isEdit ? "Edit" : "Add"} Reminder</EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <EuiForm>
                        <EuiFormRow label="Name" >
                            <EuiFieldText name="name" value={name} onChange={e => setName(e.target.value)} onKeyDown={handleKeyDown} />
                        </EuiFormRow>
                    </EuiForm>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>

                    <EuiButton isDisabled={!isValid || saving} isLoading={saving} onClick={handleSave} fill >
                        Save
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    );
}

export default AddReminder;
