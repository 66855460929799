import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import '@elastic/eui/dist/eui_theme_dark.css';

const savedTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"

let theme;
theme = savedTheme === "dark" && import('@elastic/eui/dist/eui_theme_dark.css');
theme = savedTheme === "light" && import('@elastic/eui/dist/eui_theme_light.css');
theme = savedTheme === "amsterdam Dark" && import('@elastic/eui/dist/eui_theme_amsterdam_dark.css');
theme = savedTheme === "amsterdam Light" && import('@elastic/eui/dist/eui_theme_amsterdam_light.css');

console.log(theme)

// import { StateProvider } from './StateProvider';

// const initialState = {
//     toasts: []
// };

// const reducer = (state, action) => {
//     switch (action.type) {
//         case 'addToast':
//             return {
//                 ...state,
//                 toasts: state.toasts.concat(action.newToast)
//             };
//         case 'removeToast':
//             return {
//                 ...state,
//                 toasts: state.toasts.filter(toast => toast.id !== action.removedToast.id)
//             }

//         default:
//             return state;
//     }
// };

ReactDOM.render(
    // <StateProvider initialState={initialState} reducer={reducer}>
    <App />
    // </StateProvider>
    , document.getElementById('root'));
