import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import {
    EuiButton,
    EuiText,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingContent,
} from '@elastic/eui';

import { db } from "../../../helpers/firebaseSecondary"
import EditWorkModal from './EditWorkModal';

const WorkTimer = ({ user, match: { params: { id } } }) => {
    const [userProject, setUserProject] = useState(null)
    const [currentWorkTime, setCurrentWorkTime] = useState("00:00:00")
    const [interval, setInterval] = useState(null)
    const [restoreWorkTime, setRestoreWorkTime] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const docRef = db.collection("user_project").doc(`${user.uid}_${id}`)

    const unsubscribeTasks = docRef.onSnapshot(async doc => {
        if (doc) {
            const _userProject = ({ id: doc.id, ...doc.data() })

            if (JSON.stringify(_userProject) !== JSON.stringify(userProject)) {
                setUserProject(_userProject)
            }
        }
    })

    useEffect(() => {
        return (() => {
            unsubscribeTasks()
        })
    })

    useEffect(() => () => {
        if (interval) window.clearInterval(interval)
    }, [interval])

    const handleClockIn = () => {
        if (restoreWorkTime) setRestoreWorkTime(null)

        docRef.update({ workTime: moment().valueOf() })
    }

    const cleanUpWorkTimer = async () => {
        await docRef.update({ workTime: 0 })
        window.clearInterval(interval)
        setCurrentWorkTime("00:00:00")
        setInterval(null)
    }

    const handleClockOut = async () => {
        setIsModalVisible(true)
        // await cleanUpWorkTimer()
    }

    const handleSaved = async () => {
        setIsModalVisible(false)

        await cleanUpWorkTimer()
    }

    const handleCancel = async () => {
        setRestoreWorkTime(userProject.workTime)

        await cleanUpWorkTimer()
    }

    const handleRestore = async () => {
        await docRef.update({ workTime: restoreWorkTime })

        setRestoreWorkTime(null)
    }

    if (!userProject) return <EuiLoadingContent lines={3} />

    if (!userProject.workTime || userProject.workTime <= 0) return (
        <Fragment>
            <EuiFlexGroup>
                <EuiFlexItem grow={false} >
                    <EuiButton fill onClick={handleClockIn} >Clock in</EuiButton>
                </EuiFlexItem>

                {restoreWorkTime && (
                    <EuiFlexItem grow={false} >
                        <EuiButton color="text" onClick={handleRestore} >Restore</EuiButton>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </Fragment>
    )

    const startTimer = () => {
        const tick = () => {
            const now = moment()
            const workTimeMilliseconds = now.diff(moment(userProject.workTime))
            const workTime = moment().hour(0).minute(0).second(0).millisecond(workTimeMilliseconds).format("HH:mm:ss")

            setCurrentWorkTime(workTime)
        }

        tick()

        const _interval = window.setInterval(tick, 1000);

        setInterval(_interval)
    }

    const workStart = moment(userProject.workTime).format("DD.MM.YYYY HH:mm")

    if (!interval) startTimer()

    return (
        <Fragment>
            <EuiText>
                <h3>You started working</h3>
                <p>{workStart}</p>
                <h3>Work time</h3>
                <p>{currentWorkTime}</p>
            </EuiText>

            <EuiSpacer size="xxl" />

            <EuiFlexGroup>
                <EuiFlexItem grow={false} >
                    <EuiButton fill onClick={handleClockOut} >Clock out</EuiButton>
                </EuiFlexItem>

                <EuiFlexItem grow={false} >
                    <EuiButton color="danger" onClick={handleCancel} >Cancel</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>

            {isModalVisible && (
                <EditWorkModal
                    mode="clockOut"
                    title="Clock out"
                    work={{ workFrom: userProject.workTime }}
                    user={user}
                    projectId={id}
                    closeModal={() => setIsModalVisible(false)}
                    onSaved={handleSaved}
                />
            )}
        </Fragment>
    )
}

export default WorkTimer
