import React, { useState } from 'react';
import { Redirect } from 'react-router';
import {
    EuiButton,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiLink,
} from '@elastic/eui';

import { auth } from "../../helpers/firebase"
import ForgotPassword from './ForgotPassword'

const Login = ({ shouldRedirect = true }) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [redirect, setRedirect] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const login = () => {
        setLoading(true)
        setError(null)

        auth.signInWithEmailAndPassword(username, password)
            .then(res => setRedirect(shouldRedirect))
            .catch(e => {
                setError("Wrong username or password")
                setLoading(false)
            })

    }

    const resetError = () => setError(null)

    if (redirect) return <Redirect to="/" />

    if (showForgotPassword) return <ForgotPassword onClose={() => setShowForgotPassword(false)} />

    return (
        <EuiForm isInvalid={error !== null} error={error} >
            <EuiFormRow label="Username" isInvalid={error !== null} >
                <EuiFieldText
                    name="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiFormRow label="Password" isInvalid={error !== null} >
                <EuiFieldPassword
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiSpacer />

            <EuiButton
                type="submit"
                fill
                disabled={loading}
                isLoading={loading}
                onClick={login}
            >
                Sign in
            </EuiButton>

            <EuiLink style={{ marginLeft: 16 }} onClick={() => setShowForgotPassword(true)} >Forgot password?</EuiLink>
        </EuiForm>
    );

}

export default Login;