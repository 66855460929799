import React, { useState } from 'react';
import {
    EuiButton,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
} from '@elastic/eui';

import { auth } from "../../helpers/firebaseSecondary"

const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const login = () => {
        setLoading(true)
        setError(null)

        auth.signInWithEmailAndPassword(username, password)
            .then(res => { })
            .catch(e => {
                setError("Wrong username or password")
                setLoading(false)
            })

    }

    const resetError = () => setError(null)

    return (
        <EuiForm isInvalid={error !== null} error={error} >
            <EuiFormRow label="Username" isInvalid={error !== null} >
                <EuiFieldText
                    name="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiFormRow label="Password" isInvalid={error !== null} >
                <EuiFieldPassword
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiSpacer />

            <EuiButton
                type="submit"
                fill
                disabled={loading}
                isLoading={loading}
                onClick={login}
            >
                Sign in
            </EuiButton>
        </EuiForm>
    );

}

export default Login;