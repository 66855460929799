import React, { useState, Fragment } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiPopoverTitle,
} from '@elastic/eui';

const themes = ["dark", "light", "amsterdam Dark", "amsterdam Light"]

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)

const Settings = () => {
    const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark")

    const handleThemeChange = e => {
        const _theme = e.target.value

        setTheme(_theme)
        localStorage.setItem("theme", _theme)

        window.location.reload()
    }

    return (
        <Fragment>
            <EuiPopoverTitle>Settings</EuiPopoverTitle>
            <EuiForm>
                <EuiFormRow label="Theme" >
                    <EuiSelect
                        value={theme}
                        onChange={handleThemeChange}
                        options={themes.map(theme => ({ value: theme, text: capitalize(theme) }))}
                    />
                </EuiFormRow>
            </EuiForm>

            <div style={{ width: 200 }} />
        </Fragment>
    )
}

export default Settings
