import React from 'react';
import { Redirect } from 'react-router';

import { projects } from './constants';

const ProjectsView = ({ match }) => {
    const selectedProject = projects.find(project => project.name === match.params.name)

    if (!selectedProject) return <Redirect to="/projects" />

    return (
        // <div
        //     style={{
        //         position: 'fixed',
        //         top: 64,
        //         bottom: 0,
        //         left: 0,
        //         right: 0,
        //     }}
        // >
        <iframe
            style={{
                border: 0,
                // position: 'absolute',
                height: '85vh',
                width: '100%'
            }}
            title={selectedProject.title}
            src={selectedProject.url}
        />
        // </div>
    );
}

export default ProjectsView;
