import React from 'react';
import {
    EuiCard,
    EuiIcon,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';
import { useHistory } from 'react-router';

import MyEuiPageContent from '../../components/MyEuiPageContent';
import { projects } from './constants';

const Projects = () => {
    const history = useHistory()

    return (
        <MyEuiPageContent title="Old Projects" >
            <EuiFlexGroup gutterSize="l">
                {projects.map(project => {
                    return (
                        <EuiFlexItem key={project.name}>
                            <EuiCard
                                icon={<EuiIcon size="xxl" type="logoGithub" />}
                                title={project.title}
                                description={project.url}
                                onClick={() => history.push(`/projects/${project.name}`)}
                            />
                        </EuiFlexItem>
                    );
                })}
            </EuiFlexGroup>
        </MyEuiPageContent>
    );
}

export default Projects;
