import React, { useState, useEffect, useRef } from 'react';
import {
    EuiPanel,
} from '@elastic/eui';

const Sticky = ({ children }) => {
    const divRef = useRef()

    const [useSticky, setUseSticky] = useState(false)

    const handleCheckScroll = () => {
        const handleScroll = () => {
            if (window.pageYOffset > divRef.current.offsetTop) {
                setUseSticky(true)
            } else {
                setUseSticky(false)
            }
        }

        window.addEventListener("scroll", handleScroll);

        handleScroll();

        return () => window.removeEventListener("scroll", handleScroll);
    }

    useEffect(handleCheckScroll)

    if (useSticky)
        return (
            <div
                ref={divRef}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 16,
                    right: 16,
                    zIndex: 99999,
                }}
            >
                <EuiPanel paddingSize="none" >
                    {children}
                </EuiPanel>
            </div>
        )

    return <div ref={divRef} >{children}</div>
}

export default Sticky
