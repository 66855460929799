import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import {
    EuiListGroup,
    EuiListGroupItem,
    EuiTitle,
    EuiTextColor,
} from '@elastic/eui';

import { db } from "../../helpers/firebaseSecondary"

const ProjectPicker = ({ user, onSelectProject }) => {
    const history = useHistory();

    const [projects, setProjects] = useState(null)
    const [userProjects, setUserProjects] = useState(null)

    const unsubscribeTasks = db.collection("user_project").where("userId", "==", user.uid).orderBy("createdAt", "desc").onSnapshot(async snap => {
        const docs = snap.docs

        const _userProjects = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        setUserProjects(_userProjects)

        if (JSON.stringify(_userProjects) !== JSON.stringify(userProjects)) {
            const promises = _userProjects.map(async userProject => {
                const projectSnap = await db.collection("projects").doc(userProject.projectId).get()
                const project = { id: projectSnap.id, ...projectSnap.data() }

                return project
            })

            const _projects = await Promise.all(promises)

            setProjects(_projects)
        }
    })

    useEffect(() => {
        return (() => {
            unsubscribeTasks()
        })
    })

    const handleSelectProject = project => {
        history.push(`/projectTracker/projects/${project.id}`)

        localStorage.setItem("projectTrackerProjects", JSON.stringify(projects))
    }

    return (
        <Fragment>
            {projects && (
                <EuiListGroup flush >
                    {projects.map(project => {
                        return (
                            <Fragment key={project.id} >
                                <EuiListGroupItem
                                    label={
                                        <Fragment>
                                            <EuiTitle><h2>{project.name}</h2></EuiTitle>
                                            <p><EuiTextColor color="subdued" >{project.description}</EuiTextColor></p>
                                        </Fragment>
                                    }
                                    onClick={() => handleSelectProject(project)}
                                />
                            </Fragment>
                        )
                    })}
                </EuiListGroup>
            )}
        </Fragment>
    )
}

export default ProjectPicker
