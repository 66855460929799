import React from 'react';

import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiTitle,
} from '@elastic/eui';

const MyEuiPageContent = ({ title, contentAbilities, children, center, responsive }) => {
    return (
        <EuiPageContent verticalPosition={center ? "center" : null} horizontalPosition={center ? "center" : null}  >
            {(title || contentAbilities) &&
                <EuiPageContentHeader responsive={responsive} >
                    <EuiPageContentHeaderSection>
                        <EuiTitle>
                            <h2>{title}</h2>
                        </EuiTitle>
                    </EuiPageContentHeaderSection>
                    {contentAbilities &&
                        <EuiPageContentHeaderSection>
                            {contentAbilities}
                        </EuiPageContentHeaderSection>
                    }
                </EuiPageContentHeader>
            }
            <EuiPageContentBody>{children}</EuiPageContentBody>
        </EuiPageContent>
    )
}

export default MyEuiPageContent
