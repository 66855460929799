import React from 'react'
import {
    EuiCard,
    EuiAvatar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
} from '@elastic/eui';

import MyEuiPageContent from '../../components/MyEuiPageContent';

const published = [
    {
        img: "/images/statsForFortnite.png",
        src: "https://play.google.com/store/apps/details?id=com.djmoberg.statsforfortnite",
        header: "Stats++ for Fortnite",
        desc: "My first published app. Made with Flutter.",
    },
    {
        img: "/images/shuffleboardRanking.png",
        src: "https://play.google.com/store/apps/details?id=com.djmoberg.shuffleboardtracker",
        header: "Shuffleboard Ranking",
        desc: "Shuffleboard Ranking made for Bjørnefjorden Gründerpark.",
    }
]

const unpublished = [
    {
        img: "/images/workoutv2.png",
        header: "Workout v2",
        desc: "Made with Flutter.",
    },
    {
        img: "/images/projectTrackerv2.png",
        src: "https://facelex.com/",
        header: "Project Tracker v2",
        desc: "Made with Flutter.",
    },
    {
        img: "/images/foodTracker.png",
        header: "Food Tracker",
        desc: "Made with Flutter.",
    },
]

const List = ({ list }) => (
    <EuiFlexGroup gutterSize="l">
        {list.map(app => {
            return (
                <EuiFlexItem key={app.header}>
                    <EuiCard
                        icon={<EuiAvatar name={app.header} size="xl" imageUrl={app.img} />}
                        title={app.header}
                        description={app.desc}
                        href={app.src}
                        target="_blank"
                        isDisabled={!app.src}
                    />
                </EuiFlexItem>
            );
        })}
    </EuiFlexGroup>
)

const MyApps = () => (
    <MyEuiPageContent title="My Apps" >
        <h2>Published</h2>
        <EuiSpacer />
        <List list={published} />
        <EuiSpacer size="xl" />
        <h2>Unpublished</h2>
        <EuiSpacer />
        <List list={unpublished} />
    </MyEuiPageContent>
)

export default MyApps
