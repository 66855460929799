import React, { Fragment, useState, useEffect } from 'react';

import { db } from '../../helpers/firebase'
import {
    EuiButton,
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiSpacer,
    EuiTextArea,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';

const NoteEdit = ({ user, note, onClose }) => {
    const [liveNote, setLiveNote] = useState(null)
    const [title, setTitle] = useState(note.title)
    const [noteText, setNoteText] = useState(note.note)
    const [saving, setSaving] = useState(false)

    const unsubscribe = db.collection("users").doc(user.uid).collection("notes").doc(note.id).onSnapshot((doc) => {
        setLiveNote({ id: doc.id, ...doc.data() })
    });

    useEffect(() => unsubscribe)

    const handleEdit = async (key, value) => {
        setSaving(true)

        const docRef = db.collection("users").doc(user.uid).collection("notes").doc(note.id)

        await docRef.update({ [key]: value })

        setSaving(false)
    }

    const handleTitleChange = e => {
        const value = e.target.value

        setTitle(value)

        handleEdit("title", value)
    }

    const handleNoteChange = e => {
        const value = e.target.value

        setNoteText(value)

        handleEdit("note", value)
    }

    const isSync = liveNote && liveNote.title === title && liveNote.note === noteText

    return (
        <Fragment>
            <EuiFlexGroup gutterSize="s" alignItems="center" >
                <EuiFlexItem grow={false} >
                    <EuiButton iconType="arrowLeft" onClick={onClose} >Back</EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false} >
                    <span>{isSync ? "Synced" : "Syncing..."}</span>
                </EuiFlexItem>
                <EuiFlexItem grow={false} >
                    <span>{saving ? "Saving..." : "Saved!"}</span>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />

            <EuiForm>
                <EuiFormRow>
                    <EuiFieldText
                        placeholder="Title"
                        value={title}
                        onChange={handleTitleChange}
                    />
                </EuiFormRow>
                <EuiFormRow>
                    <EuiTextArea
                        resize="both"
                        placeholder="Note"
                        value={noteText}
                        onChange={handleNoteChange}
                    />
                </EuiFormRow>
            </EuiForm>
        </Fragment>
    );
}

export default NoteEdit;
