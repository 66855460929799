import React, { Fragment, Component, useState, useEffect } from 'react'
import moment from 'moment'
import {
    EuiBasicTable,
    EuiDescriptionList,
    EuiButtonIcon,
    EuiStat,
    EuiPanel,
    EuiSpacer,
    EuiFlexItem,
    EuiFlexGroup,
    EuiDatePicker,
} from '@elastic/eui';
import { RIGHT_ALIGNMENT } from '@elastic/eui/lib/services';

import { db } from "../../../helpers/firebaseSecondary"
import EditWorkModal from './EditWorkModal';

const Overview = ({ user, match: { params: { id } } }) => {
    const [work, setWork] = useState(null)
    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({})
    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentEditWork, setCurrentEditWork] = useState(null)
    const [fromDate, setFromDate] = useState(moment())
    const [toDate, setToDate] = useState(moment())

    const unsubscribeTasks = db.collection("projects").doc(id).collection("work").orderBy("workFrom", "desc").onSnapshot(async snap => {
        const docs = snap.docs

        const _work = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        if (JSON.stringify(_work) !== JSON.stringify(work)) {
            setWork(_work)
            setFromDate(moment(_work[_work.length - 1].workFrom))
            setToDate(moment(_work[0].workFrom))
        }
    })

    useEffect(() => {
        return (() => {
            unsubscribeTasks()
        })
    })

    const toggleDetails = work => {
        const _itemIdToExpandedRowMap = { ...itemIdToExpandedRowMap };

        if (_itemIdToExpandedRowMap[work.id]) {
            delete _itemIdToExpandedRowMap[work.id];
        } else {
            const listItems = [
                {
                    title: "Comment",
                    description: <p style={{ whiteSpace: "pre" }} >{work.comment}</p>
                }
            ]

            _itemIdToExpandedRowMap[work.id] = <EuiDescriptionList listItems={listItems} />
        }

        setItemIdToExpandedRowMap(_itemIdToExpandedRowMap);
    }

    const getWorkHours = work => moment(work.workTo).diff(moment(work.workFrom), "hours", true)

    const columns = [
        {
            field: 'date',
            name: 'Date',
            width: "10%",
            render: (_, work) => (
                <span>{moment(work.workFrom).format("DD.MM.YYYY")}</span>
            ),
            footer: <em>Page totals:</em>,
        },
        {
            field: 'workFrom',
            name: 'From',
            width: "10%",
            render: workFrom => (
                <span>{moment(workFrom).format("HH:mm")}</span>
            ),
        },
        {
            field: 'workTo',
            name: 'To',
            width: "10%",
            render: workTo => (
                <span>{moment(workTo).format("HH:mm")}</span>
            ),
        },
        {
            field: 'hours',
            name: 'Hours',
            width: "10%",
            render: (_, work) => (
                <span>{getWorkHours(work).toFixed(2)}</span>
            ),
            footer: ({ items }) => (
                <span>{items.reduce((acc, work) => acc + getWorkHours(work), 0).toFixed(2)} hours</span>
            ),
        },
        {
            field: 'comment',
            name: 'Comment',
            truncateText: true,
        },
        {
            name: 'Actions',
            actions: [
                {
                    name: 'Delete',
                    description: 'Delete this work',
                    icon: 'trash',
                    color: 'danger',
                    type: 'icon',
                    onClick: () => { },
                    isPrimary: true,
                    'data-test-subj': 'action-delete',
                },
                {
                    name: 'Edit',
                    isPrimary: true,
                    description: 'Edit this work',
                    icon: 'pencil',
                    type: 'icon',
                    onClick: work => {
                        setCurrentEditWork(work)
                        setIsModalVisible(true)
                    },
                    'data-test-subj': 'action-edit',
                },
            ],
        },
        {
            align: RIGHT_ALIGNMENT,
            width: '40px',
            isExpander: true,
            render: item => (
                <EuiButtonIcon
                    onClick={() => toggleDetails(item)}
                    aria-label={itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'}
                    iconType={itemIdToExpandedRowMap[item.id] ? 'arrowUp' : 'arrowDown'}
                />
            ),
        },
    ];

    const filteredWork = work ? work.filter(row => {
        return row.workFrom >= fromDate.hour(0).minute(0).valueOf() && row.workTo <= toDate.hour(23).minute(59).valueOf()
    }) : []

    const items = filteredWork ? filteredWork.reduce((acc, row, index) => {
        if (acc.length < pageSize && index >= pageIndex * pageSize) {
            return [...acc, row]
        }

        return acc
    }, []) : [];

    const onTableChange = ({ page = {} }) => {
        const { index: pageIndex, size: pageSize } = page

        setPageIndex(pageIndex)
        setPageSize(pageSize)
    }

    const pagination = {
        pageIndex,
        pageSize,
        totalItemCount: filteredWork ? filteredWork.length : 0,
        pageSizeOptions: [3, 5, 8],
        hidePerPageOptions: false,
    }

    class DatePanel extends Component {
        render() {
            const { onClick, value, description } = this.props

            return (
                <EuiPanel onClick={onClick} >
                    <EuiStat
                        title={value}
                        description={description}
                        textAlign="right"
                        isLoading={!work}
                    />
                </EuiPanel>
            )
        }
    }

    class FromDatePanel extends Component {
        render = () => <DatePanel {...this.props} description="From date" />
    }

    class ToDatePanel extends Component {
        render = () => <DatePanel {...this.props} description="To date" />
    }

    const minDate = work ? moment(work[work.length - 1].workFrom) : moment()
    const maxDate = work ? moment(work[0].workFrom) : moment()

    return (
        <Fragment>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={filteredWork ? filteredWork.reduce((acc, work) => acc + getWorkHours(work), 0).toFixed(2) : ""}
                            description="Total hours"
                            textAlign="right"
                            isLoading={!work}
                        />
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiDatePicker
                        selected={fromDate}
                        onChange={setFromDate}
                        dateFormat="DD.MM.YYYY"
                        maxDate={moment(toDate.valueOf())}
                        minDate={minDate}
                        customInput={<FromDatePanel />}
                        fullWidth
                    />
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiDatePicker
                        selected={toDate}
                        onChange={setToDate}
                        dateFormat="DD.MM.YYYY"
                        maxDate={maxDate}
                        minDate={moment(fromDate.valueOf())}
                        customInput={<ToDatePanel />}
                        fullWidth
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />

            <EuiBasicTable
                items={items}
                itemId="id"
                columns={columns}
                itemIdToExpandedRowMap={itemIdToExpandedRowMap}
                hasActions
                pagination={pagination}
                onChange={onTableChange}
            />

            {isModalVisible && (
                <EditWorkModal
                    mode="edit"
                    title="Edit"
                    work={currentEditWork}
                    user={user}
                    projectId={id}
                    closeModal={() => setIsModalVisible(false)}
                    onSaved={() => setIsModalVisible(false)}
                />
            )}
        </Fragment>
    )
}

export default Overview
