import React, { Fragment, useState, useEffect } from 'react';
import { EuiFlexGrid, EuiFlexItem, EuiListGroup, EuiListGroupItem, EuiText, EuiTextColor, EuiButtonEmpty } from '@elastic/eui';

import { db } from '../../helpers/firebase'
import LinkEditModal from './LinkEditModal';
import { addToast } from '../../helpers/toasts';

const GroupView = ({ user }) => {
    // const maxCharSize = 20

    const [links, setLinks] = useState([])
    const [groups, setGroups] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [editLink, setEditLink] = useState(null)

    const unsubscribeLinks = db.collection("users").doc(user.uid).collection("links").onSnapshot((snap) => {
        const docs = snap.docs

        const links = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        setLinks(links)
    });

    const unsubscribeGroups = db.collection("users").doc(user.uid).collection("groups").onSnapshot((snap) => {
        const docs = snap.docs

        const groups = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        setGroups(groups)
    });

    useEffect(() => {
        return (() => {
            unsubscribeLinks()
            unsubscribeGroups()
        })
    })

    const handleDeleteGroup = async ({ id, ...group }) => {
        // setSaving(true)

        await db.collection("users").doc(user.uid).collection("groups").doc(id).delete()

        // setSaving(false)

        addToast({
            type: 'undo',
            collection: "groups",
            docId: id,
            data: group,
            title: 'Group deleted',
        })
    }

    const handleDeleteLink = async ({ id, ...link }) => {
        // setSaving(true)

        await db.collection("users").doc(user.uid).collection("links").doc(id).delete()

        // setSaving(false)

        addToast({
            type: 'undo',
            collection: "links",
            docId: id,
            data: link,
            title: 'Link deleted',
        })
    }

    // const trimSize = text => {
    //     if (text.length <= maxCharSize) return text

    //     return `${text.substring(0, maxCharSize)}...`
    // }

    // const getColumns = () => {
    //     const settingsColumns = localStorage.getItem("settings-columns")

    //     return settingsColumns ? settingsColumns : 4
    // }

    const getGroupsWithLinks = () => {
        return groups.map(group => {
            return { ...group, links: links.filter(link => link.group === group.id) }
        });
    }

    const openLinkEditModal = link => {
        setEditLink(link)
        setIsModalVisible(true)

        document.activeElement.blur()
    }

    return (
        <Fragment>
            {groups && groups.length > 0 && (
                <EuiFlexGrid columns={3} >
                    {getGroupsWithLinks().map(group => (
                        <EuiFlexItem key={group.name} >
                            <EuiText>
                                <h3>{group.name}</h3>
                                {group.links.length <= 0 && (
                                    <EuiTextColor color="subdued">No links</EuiTextColor>
                                )}
                            </EuiText>
                            {group.links.length <= 0 && (
                                <EuiButtonEmpty
                                    color="danger"
                                    onClick={() => handleDeleteGroup(group)}
                                >
                                    Delete group
                                </EuiButtonEmpty>
                            )}
                            <EuiListGroup>
                                {group.links.map((link, index) => {
                                    const url = link.url
                                    const parsedUrl = url.substring(0, 4) !== "http" ? `https://${url}` : url

                                    return (
                                        <EuiListGroupItem
                                            key={index}
                                            // showToolTip
                                            label={link.name}
                                            href={parsedUrl}
                                            target="_blank"
                                            extraAction={{
                                                color: 'subdued',
                                                onClick: () => openLinkEditModal(link),
                                                iconType: 'indexEdit',
                                                iconSize: 's',
                                                alwaysShow: false,
                                                'aria-label': `Edit ${link.name}`,
                                            }}
                                        />
                                    )
                                })}
                            </EuiListGroup>
                        </EuiFlexItem>
                    ))}
                </EuiFlexGrid>
            )}
            {isModalVisible &&
                <LinkEditModal
                    user={user}
                    groups={groups}
                    setIsModalVisible={setIsModalVisible}
                    link={editLink}
                    onDeleteLink={handleDeleteLink}
                />
            }
            {/* {groups && groups.length > 0 && (
                <Grid columns={getColumns()} >
                    <Grid.Row>
                        {getGroupsWithLinks().map(group => (
                            <Grid.Column key={group.name} >
                                {edit ?
                                    <Grid>
                                        <Grid.Row columns="2" >
                                            <Grid.Column>
                                                <Header as="h3" content={trimSize(group.name)} />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button size="tiny" icon="trash" onClick={() => handleDeleteGroup(group.id)} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    :
                                    <Header as="h3" content={trimSize(group.name)} />
                                }
                                <List style={{ marginBottom: 32 }} animated={!edit} selection={!edit} >
                                    {group.links.map((link, index) => (
                                        <Popup
                                            key={link.name + link.url}
                                            header={link.name}
                                            content={link.url}
                                            openOnTriggerMouseEnter={link.name <= maxCharSize || link.url <= maxCharSize}
                                            trigger={
                                                <List.Item
                                                    onClick={!edit ? () => openUrl(link.url) : null}
                                                >
                                                    {edit &&
                                                        <List.Content floated='right'>
                                                            <Button size="tiny" icon="trash" onClick={() => handleDeleteLink(link.id)} />
                                                        </List.Content>
                                                    }
                                                    <List.Content>
                                                        <List.Header>{trimSize(link.name)}</List.Header>
                                                        <List.Description>{trimSize(link.url)}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            }
                                        />
                                    ))}
                                </List>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </Grid>
            )
            } */}
        </Fragment>
    );
}

export default GroupView;
