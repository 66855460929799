import React, { Fragment, useState } from 'react';
import { Route } from "react-router-dom"
import { EuiLoadingContent, EuiSpacer } from '@elastic/eui';

import { auth } from "../../helpers/firebase"
import Login from '../../pages/Login'
import MyEuiPageContent from '../MyEuiPageContent';

const AuthRoute = ({ path, component }) => {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    auth.onAuthStateChanged(user => {
        setLoading(false)
        setUser(user)
    })

    const pathArray = path.split("/")
    const title = pathArray[pathArray.length - 1]
    const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1)

    if (loading) return (
        <MyEuiPageContent title={titleCapitalized} >
            <EuiLoadingContent lines={3} />
            <EuiSpacer />
            <EuiLoadingContent lines={3} />
        </MyEuiPageContent>
    )

    if (!user) return <Login shouldRedirect={false} />

    return (
        <Fragment>
            <Route path={path} component={({ ...props }) => component({ ...props, user, title: titleCapitalized })} />
        </Fragment>
    );
}

export default AuthRoute;
