import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios';
import {
    EuiImage,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiBetaBadge,
    EuiDescriptionList,
    EuiDescriptionListTitle,
    EuiDescriptionListDescription,
    EuiSpacer,
    EuiLink,
} from '@elastic/eui';

import MyEuiPageContent from '../../components/MyEuiPageContent';

const GitHub = () => {
    const [user, setUser] = useState(null)
    const [repos, setRepos] = useState(null)

    useEffect(() => {
        axios.get("https://api.github.com/users/djmoberg").then(res => {
            const _user = res.data

            setUser(_user)

            axios.get(_user.repos_url).then(res => {
                const compare = (a, b) => {
                    if (a.updated_at > b.updated_at) return -1
                    if (a.updated_at < b.updated_at) return 1
                    return 0;
                }

                const _repos = res.data
                _repos.sort(compare)

                setRepos(_repos)
            })
        })
    }, [])

    const dateFormat = { day: "2-digit", month: "2-digit", year: "numeric", hour: "numeric", minute: "numeric" }

    return (
        <MyEuiPageContent title="My GitHub" >
            {user && (
                <Fragment>
                    <EuiFlexGroup gutterSize="xl" >
                        <EuiFlexItem>
                            <EuiImage
                                url={user.avatar_url}
                                caption={user.login}
                                alt="avatar"
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={8} >
                            <EuiTitle>
                                <h3>Repositories &nbsp;
                                    <EuiBetaBadge label={`${user.public_repos}`} />
                                </h3>
                            </EuiTitle>

                            <EuiSpacer />

                            {repos && (
                                <EuiDescriptionList>
                                    {repos.map(repo => (
                                        <Fragment key={repo.id} >
                                            <EuiDescriptionListTitle>
                                                <EuiLink external href={repo.html_url} target="_blank" >
                                                    {repo.name}
                                                </EuiLink>
                                            </EuiDescriptionListTitle>
                                            <EuiDescriptionListDescription>
                                                <EuiFlexGroup>
                                                    <EuiFlexItem>
                                                        {repo.language}
                                                    </EuiFlexItem>
                                                    <EuiFlexItem grow={6} >
                                                        Updated {new Date(repo.updated_at).toLocaleDateString("no", dateFormat)}
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiDescriptionListDescription>
                                        </Fragment>
                                    ))}
                                </EuiDescriptionList>
                            )}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </Fragment>
            )}
        </MyEuiPageContent>
    )
}

export default GitHub
