import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import {
	EuiPage,
	EuiPageBody,
	EuiPageHeader,
	EuiPageHeaderSection,
	EuiPageSideBar,
	EuiTitle,
} from '@elastic/eui';

import SideNav from './SideNav';
import AuthStatus from './components/Auth/AuthStatus';

import Home from './pages/Home';
import Links from './pages/Links';
import HttpStatus from './pages/HttpStatus';
import Projects from './pages/Projects';
import ProjectsView from './pages/Projects/ProjectsView';
import MyApps from './pages/MyApps';
import Login from './pages/Login';
import AuthRoute from './components/Auth/AuthRoute';
import Notes from './pages/Notes';
import GitHub from './pages/GitHub';
import UserSettings from './pages/UserMenu/UserSettings';
import Toasts from './components/Toasts';

import useIsDesktop from './helpers/useIsDesktop'
import ProjectTracker from './pages/ProjectTracker';
import WhenDidI from './pages/WhenDidI';

function App() {
	const isDesktop = useIsDesktop()

	return (
		<Router>
			<EuiPage>
				<EuiPageSideBar>
					<SideNav />
				</EuiPageSideBar>
				<EuiPageBody style={isDesktop ? { overflow: 'scroll', scrollbarWidth: 'thin', maxHeight: 'calc(100vh - 32px)' } : {}} >
					<EuiPageHeader responsive={false} >
						<EuiPageHeaderSection>
							<EuiTitle size="l">
								<h1>djmoberg.com</h1>
							</EuiTitle>
						</EuiPageHeaderSection>
						<EuiPageHeaderSection>
							<AuthStatus />
						</EuiPageHeaderSection>
					</EuiPageHeader>
					<Switch>
						<Route path="/" exact component={Home} />

						<Route path="/projects/:name" component={ProjectsView} />

						<Route path="/projects" component={Projects} />
						<AuthRoute path="/links" component={Links} />
						<AuthRoute path="/notes" component={Notes} />
						<AuthRoute path="/whenDidI" component={WhenDidI} />
						<Route path="/myApps" component={MyApps} />
						<Route path="/github" component={GitHub} />
						<Route path="/login" component={Login} />

						<AuthRoute path="/user/settings" component={UserSettings} />

						{/* Project Tracker */}
						<Route path="/projectTracker" component={ProjectTracker} />

						<Route component={() => <HttpStatus code="404" />} />
					</Switch>
				</EuiPageBody>
			</EuiPage>
			<Toasts />
		</Router>
	);
}

export default App;
