import { useState, useEffect } from 'react';

const useIsDesktop = () => {
    const [isDesktop, setIsDesktop] = useState(true)

    const handleIsDesktop = () => {
        const handleResize = () => setIsDesktop(window.innerWidth > 767)

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }

    useEffect(handleIsDesktop)

    return isDesktop
}

export default useIsDesktop