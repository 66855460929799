import React, { useState, useEffect } from 'react';
import {
    EuiGlobalToastList,
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';

import { getToasts, setToasts as setToastsL, removeToast as removeToastL } from '../helpers/toasts';
import { setDocByCollection } from '../helpers/firebaseActions';

const Toasts = () => {
    const [toasts, setToasts] = useState([])

    // const addToast = toast => setToasts(toasts.concat(toast))

    const removeToast = removedToast => {
        const newToasts = toasts.filter(toast => toast.id !== removedToast.id)

        setToasts(newToasts)
        setToastsL(newToasts)
    }

    const findToasts = () => {
        // console.log("Finding")

        const _toasts = getToasts()

        const toastsMapped = _toasts.map(({ type, collection, docId, data, ...toast }) => {
            if (type === "undo") {
                toast.iconType = "iInCircle"

                toast.text = (
                    <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                size="s"
                                onClick={() => {
                                    setDocByCollection(collection, docId, data)
                                    removeToastL(toast.id)
                                }}
                            >
                                Undo
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                )
            }

            return toast
        })

        setToasts(toastsMapped)
    }

    useEffect(() => {
        // const interval = 
        setInterval(findToasts, 1000)

        // return clearInterval(interval)
    }, [])

    return (
        <EuiGlobalToastList
            toasts={toasts}
            dismissToast={removeToast}
            toastLifeTimeMs={6000}
        />
    )
}

export default Toasts
