import React from 'react';
import { useHistory } from 'react-router';
import {
    EuiImage,
    EuiCard,
    EuiIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';

import MyEuiPageContent from '../../components/MyEuiPageContent';

const Home = () => {
    const history = useHistory()

    const handleClick = to => history.push(to)

    const Item = ({ icon, title, description, to }) => (
        <EuiFlexItem>
            <EuiCard
                icon={<EuiIcon size="xxl" type={icon} />}
                title={title}
                description={description}
                onClick={() => handleClick(to)}
            />
        </EuiFlexItem>
    )

    return (
        <MyEuiPageContent title="Welcome to djmoberg.com" >
            <EuiTitle><h2>Apps</h2></EuiTitle>
            <EuiFlexGroup gutterSize="l">
                <Item
                    icon="link"
                    title="Links"
                    description="App for saving links."
                    to="/links"
                />
                <Item
                    icon="notebookApp"
                    title="Notes"
                    description="App for taking notes."
                    to="/notes"
                />
                <Item
                    icon="calendar"
                    title="Project Tracker"
                    description="App for tracking work done on your projects."
                    to="/projectTracker"
                />
            </EuiFlexGroup>

            <EuiSpacer />

            <EuiTitle><h2>Other Projects</h2></EuiTitle>
            <EuiFlexGroup gutterSize="l">
                <Item
                    icon="logoGithub"
                    title="My GitHub"
                    description="List of my public repositories."
                    to="/github"
                />
                <Item
                    icon="discoverApp"
                    title="My Apps"
                    description="List of my mobile apps."
                    to="/myApps"
                />
                <Item
                    icon="recentlyViewedApp"
                    title="Old Projects"
                    description="List of some of my old proejcts."
                    to="/projects"
                />
            </EuiFlexGroup>

            <EuiSpacer size="xxl" />

            <EuiFlexGroup justifyContent="center" alignItems="center" >
                <EuiFlexItem grow={false} >
                    <EuiImage url="https://www.askideas.com/media/08/Funny-Cat-Angry-Face.jpg" alt="cat" size="xl" />
                </EuiFlexItem>
            </EuiFlexGroup>
        </MyEuiPageContent>
    )
}

export default Home
