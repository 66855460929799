import React, { Fragment, useState } from 'react'
import moment from 'moment'
import {
    EuiButton,
    // EuiForm,
    EuiFormRow,
    EuiTextArea,
    EuiDatePicker,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';

import { getHoursFromMoment, round } from '../../../helpers/utils'
import useIsDesktop from '../../../helpers/useIsDesktop';
import { db } from '../../../helpers/firebaseSecondary'

const AddWork = ({ user, match: { params: { id } } }) => {
    const isDesktop = useIsDesktop()

    const [date, setDate] = useState(moment())
    const [workFrom, setWorkFrom] = useState(moment())
    const [workTo, setWorkTo] = useState(moment())
    const [comment, setComment] = useState("")
    const [saving, setSaving] = useState(false)

    const workHours = round(getHoursFromMoment(workFrom, workTo))

    const isValidTime = workFrom.valueOf() < workTo.valueOf()
    const errorText = "Time from must be before time to"
    const isValid = comment.length > 0 && isValidTime

    const handleSave = async () => {
        setSaving(true)

        const _workFrom = moment().set({ day: date.day(), month: date.month(), year: date.year(), hour: workFrom.hour(), minute: workFrom.minute() })
        const _workTo = moment().set({ day: date.day(), month: date.month(), year: date.year(), hour: workTo.hour(), minute: workTo.minute() })

        const data = {
            comment,
            workFrom: _workFrom.valueOf(),
            workTo: _workTo.valueOf(),
            userId: user.uid,
        }

        await db.collection("projects").doc(id).collection("work").doc().set(data)

        setSaving(false)
    }

    return (
        <Fragment>
            {/* <EuiForm> */}
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow label="Date" >
                        <EuiDatePicker
                            selected={date}
                            onChange={setDate}
                            dateFormat="DD.MM.YYYY"
                            maxDate={moment()}
                            inline={isDesktop}
                        />
                    </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiFormRow label="From" isInvalid={!isValidTime} error={errorText} >
                        <EuiDatePicker
                            showTimeSelect
                            showTimeSelectOnly
                            selected={workFrom}
                            onChange={setWorkFrom}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            isInvalid={!isValidTime}
                            inline={isDesktop}
                            maxTime={workTo}
                            minTime={moment().hour(0).minute(0)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiFormRow label="To" isInvalid={!isValidTime} error={errorText} >
                        <EuiDatePicker
                            showTimeSelect
                            showTimeSelectOnly
                            selected={workTo}
                            onChange={setWorkTo}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            isInvalid={!isValidTime}
                            inline={isDesktop}
                            maxTime={moment().hour(23).minute(59)}
                            minTime={workFrom}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup justifyContent="center" alignItems="center" >
                <EuiFlexItem>
                    <EuiFormRow label="Comment" >
                        <EuiTextArea required name="comment" value={comment} onChange={e => setComment(e.target.value)} />
                    </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiFormRow label="Hours" >
                        <span>{workHours}</span>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem grow={false} >
                    <EuiFormRow hasEmptyLabelSpace >
                        <EuiButton isDisabled={!isValid || saving} isLoading={saving} onClick={handleSave} fill >Save</EuiButton>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            {/* </EuiForm> */}
        </Fragment>
    )
}

export default AddWork
