import React, { useState } from 'react'
import moment from 'moment'
import {
    EuiButton,
    EuiButtonEmpty,
    // EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiTextArea,
    EuiDatePicker,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
} from '@elastic/eui';

import { getHoursFromMoment, round } from '../../../helpers/utils'
import { db } from '../../../helpers/firebaseSecondary'

const EditWorkModal = ({ user, closeModal, title, work, mode, onSaved, projectId }) => {
    const [workFrom, setWorkFrom] = useState(work && work.workFrom ? moment(work.workFrom) : moment())
    const [workTo, setWorkTo] = useState(work && work.workTo ? moment(work.workTo) : moment())
    const [comment, setComment] = useState(work && work.comment ? work.comment : "")
    const [saving, setSaving] = useState(false)

    const isClockOut = mode === "clockOut"
    const isEdit = mode === "edit"
    const workHours = round(getHoursFromMoment(workFrom, workTo))

    const isValidTime = workFrom.valueOf() < workTo.valueOf()
    const errorText = "Time from must be before time to"
    const isValid = comment.length > 0 && isValidTime

    const handleSave = async () => {
        setSaving(true)

        const data = {
            comment,
            workFrom: workFrom.valueOf(),
            workTo: workTo.valueOf(),
            userId: user.uid,
        }

        if (isClockOut) await db.collection("projects").doc(projectId).collection("work").doc().set(data)

        if (isEdit) await db.collection("projects").doc(projectId).collection("work").doc(work.id).update(data)

        setSaving(false)

        if (onSaved) onSaved()
    }

    // const getExcludedFromMoments = m => {
    //     const moments = []

    //     let currentMoment = moment(m.valueOf())

    //     while (currentMoment.hour() < 23 || currentMoment.minute() < 59) {
    //         moments.push(currentMoment)
    //         currentMoment = moment(currentMoment.valueOf())
    //         currentMoment.add(1, 'minutes')
    //     }

    //     return moments
    // }

    // const getExcludedToMoments = m => {
    //     const moments = []

    //     let currentMoment = moment(m.valueOf())

    //     while (currentMoment.hour() > 0 || currentMoment.minute() > 0) {
    //         moments.push(currentMoment)
    //         currentMoment = moment(currentMoment.valueOf())
    //         currentMoment.subtract(1, 'minutes')
    //     }

    //     return moments
    // }

    return (
        <EuiOverlayMask>
            <EuiModal onClose={closeModal} initialFocus="[name=comment]">
                <EuiModalHeader>
                    <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <EuiForm>
                        <EuiFormRow label="Date" >
                            <EuiDatePicker
                                selected={workFrom}
                                onChange={setWorkFrom}
                                dateFormat="DD.MM.YYYY"
                                disabled={isClockOut || isEdit}
                            />
                        </EuiFormRow>

                        <EuiFormRow label="From" isInvalid={!isValidTime} error={errorText} >
                            <EuiDatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                selected={workFrom}
                                onChange={setWorkFrom}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                disabled={isClockOut}
                                isInvalid={!isValidTime}
                            // excludeTimes={getExcludedFromMoments(workTo)}
                            />
                        </EuiFormRow>

                        <EuiFormRow label="To" isInvalid={!isValidTime} error={errorText} >
                            <EuiDatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                selected={workTo}
                                onChange={setWorkTo}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                disabled={isClockOut}
                                isInvalid={!isValidTime}
                            // excludeTimes={getExcludedToMoments(workFrom)}
                            />
                        </EuiFormRow>

                        <EuiFormRow label="Hours" >
                            <span>{workHours}</span>
                        </EuiFormRow>

                        <EuiFormRow label="Comment" >
                            <EuiTextArea required name="comment" value={comment} onChange={e => setComment(e.target.value)} />
                        </EuiFormRow>
                    </EuiForm>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>

                    <EuiButton isDisabled={!isValid || saving} isLoading={saving} onClick={handleSave} fill >
                        Save
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    )
}

export default EditWorkModal
