import React from 'react'
import MyEuiPageContent from '../../components/MyEuiPageContent'
import { EuiImage } from '@elastic/eui'

const HttpStatus = ({ code }) => (
    <MyEuiPageContent center >
        <EuiImage
            alt={code}
            url={`https://http.cat/${code}`}
            size="original"
        />
    </MyEuiPageContent>
)

export default HttpStatus
