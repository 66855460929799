import React, { Fragment, useState } from 'react';
import { EuiButton, EuiSpacer } from '@elastic/eui';

import MyEuiPageContent from '../../components/MyEuiPageContent';
import NotesView from './NotesView';
import NoteEdit from './NoteEdit';

import { newNote } from './helpers/firebase'

const Notes = ({ user, title }) => {
    const [note, setNote] = useState(null)

    const handleNoteClick = setNote
    const handleClose = () => setNote(null)
    const handleNew = async () => {
        const note = await newNote(user)

        setNote(note)
    }

    const isNoteEdit = note !== null

    return (
        <MyEuiPageContent title={title} >
            {!isNoteEdit && (
                <Fragment>
                    <EuiButton onClick={handleNew} >
                        New
                    </EuiButton>
                    <EuiSpacer />
                </Fragment>
            )}
            {isNoteEdit ?
                <NoteEdit user={user} note={note} onClose={handleClose} />
                :
                <NotesView user={user} onNoteClick={handleNoteClick} />
            }
        </MyEuiPageContent>
    )
}

export default Notes
