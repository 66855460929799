import React, { Fragment, useState } from 'react';
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiLink,
    EuiTitle,
} from '@elastic/eui';

import { auth } from "../../helpers/firebase"

const ForgotPassword = ({ onClose }) => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const send = () => {
        setLoading(true)
        setError(null)

        auth.sendPasswordResetEmail(email)
            .then(res => setSuccess(true))
            .catch(e => {
                setError(e.message)
                setLoading(false)
            })

    }

    const resetError = () => setError(null)

    if (success) return (
        <Fragment>
            <EuiTitle><h3>Please check your email</h3></EuiTitle>

            <EuiLink onClick={onClose} >Back to login</EuiLink>
        </Fragment>
    )

    return (
        <Fragment>
            <EuiTitle><h3>Send password reset mail</h3></EuiTitle>

            <EuiSpacer size="xxl" />

            <EuiForm isInvalid={error !== null} error={error} >
                <EuiFormRow label="Email" isInvalid={error !== null} >
                    <EuiFieldText
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onFocus={resetError}
                    />
                </EuiFormRow>

                <EuiSpacer />

                <EuiButton
                    type="submit"
                    fill
                    disabled={loading}
                    isLoading={loading}
                    onClick={send}
                >
                    Send
                </EuiButton>

                <EuiLink style={{ marginLeft: 16 }} onClick={onClose} >Back to login</EuiLink>
            </EuiForm>
        </Fragment>
    );

}

export default ForgotPassword;