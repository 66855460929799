import { db } from '../../../helpers/firebase'

export const newNote = async user => {
    const docRef = db.collection("users").doc(user.uid).collection("notes").doc()

    await docRef.set({ title: "", note: "" })

    const note = { id: docRef.id, title: "", note: "" }

    return note
}
