import React, { useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldPassword,
    EuiButton,
    EuiSpacer,
} from '@elastic/eui';

import { auth } from "../../../helpers/firebase"

const ChangePassword = () => {
    const [loading, setLoading] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [newPassword2, setNewPassword2] = useState("")
    const [error, setError] = useState(null)

    const updatePassword = async () => {
        setLoading(true)

        try {
            await auth.currentUser.updatePassword(newPassword)

            setNewPassword("")
            setNewPassword2("")
        } catch (error) {
            setError(error.message)
        }

        setLoading(false)
    }

    const resetError = () => setError(null)

    const isValid = newPassword.length >= 6 && newPassword === newPassword2
    const isRepeatError = newPassword.length >= 6 && newPassword2.length >= 6 && newPassword !== newPassword2

    return (
        <EuiForm>
            <EuiFormRow label="New Password" helpText="Min. 6 characters" isInvalid={error !== null} error={error} >
                <EuiFieldPassword
                    name="newPassword"
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiFormRow label="Repeat New Password" isInvalid={isRepeatError} error="Passwords are not equal" >
                <EuiFieldPassword
                    name="newPassword2"
                    value={newPassword2}
                    onChange={e => setNewPassword2(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiSpacer />

            <EuiButton
                type="submit"
                fill
                isDisabled={!isValid}
                isLoading={loading}
                onClick={updatePassword}
            >
                Save
            </EuiButton>
        </EuiForm>
    )
}

export default ChangePassword
