import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router';
import { EuiSideNav, EuiHorizontalRule, EuiLink, EuiFlexItem, EuiFlexGroup, EuiPopover, EuiButtonIcon, EuiImage, EuiSpacer } from '@elastic/eui';

import Settings from './Settings';
import useIsDesktop from './helpers/useIsDesktop';
import Sticky from './components/Sticky';

const SideNav = () => {
    const history = useHistory()
    const isDesktop = useIsDesktop()

    const [isSideNavOpenOnMobile, setIsSideNavOpenOnMobile] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const toggleOpenOnMobile = () => setIsSideNavOpenOnMobile(!isSideNavOpenOnMobile)

    const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

    const isLeftClickEvent = (event) => event.button === 0

    const onClick = (event, to, shouldToggle = true) => {
        if (event.defaultPrevented) {
            return
        }

        if (event.target.getAttribute('target')) {
            return
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
            return
        }

        event.preventDefault()

        history.push(to)

        if (shouldToggle) toggleOpenOnMobile()
    }

    const createItem = (name, to, data = {}) => ({
        ...data,
        id: name,
        name,
        isSelected: history.location.pathname === to,
        onClick: to ? (e) => onClick(e, to, data && data.items ? false : true) : null,
    })

    const pathArray = history.location.pathname.split("/")
    const isProjectTrackerProject = pathArray.length >= 4 && pathArray[1] === "projectTracker" && pathArray[2] === "projects"

    const projectTrackerItems = [
        createItem("Projects", "/projectTracker/projects"),
    ]

    if (isProjectTrackerProject) {
        const projectItems = [
            createItem("Work Timer", `/projectTracker/projects/${pathArray[3]}/workTimer`),
            createItem("Overview", `/projectTracker/projects/${pathArray[3]}/overview`),
            createItem("Add Work", `/projectTracker/projects/${pathArray[3]}/addWork`),
        ]

        const savedProjects = localStorage.getItem("projectTrackerProjects")

        if (savedProjects) {
            try {
                const projects = JSON.parse(savedProjects)
                const projectsItems = projects.map(project => {
                    const data = pathArray[3] === project.id ? { items: projectItems } : null

                    return createItem(project.name, `/projectTracker/projects/${project.id}`, data)
                })

                projectTrackerItems[0].items = projectsItems
            } catch (error) {
                projectItems.unshift(createItem("Home", `/projectTracker/projects/${pathArray[3]}`))
                projectTrackerItems[0].items = projectItems
            }
        } else {
            projectItems.unshift(createItem("Home", `/projectTracker/projects/${pathArray[3]}`))
            projectTrackerItems[0].items = projectItems
        }
    }

    const sideNav = [
        // createItem("Home", "/"),
        createItem("Apps", null, {
            items: [
                createItem("Links", "/links"),
                createItem("Notes", "/notes"),
                createItem("Project Tracker", "/projectTracker", {
                    items: projectTrackerItems,
                }),
                createItem("When Did I?", "/whenDidI"),
            ]
        }),
        createItem("Other Projects", null, {
            items: [
                createItem("My GitHub", "/github"),
                createItem("My Apps", "/myApps"),
                createItem("Old Projects", "/projects", {
                    items: [
                        createItem("Project Tracker New", "/projects/projectTrackerNew"),
                        createItem("Project Tracker Old", "/projects/projectTrackerOld"),
                        createItem("Project Tracker", "/projects/projectTracker"),
                        createItem("JSON Editor", "/projects/jsonEditor"),
                    ]
                }),
            ]
        }),
    ]

    return (
        <Fragment>
            <EuiFlexGroup gutterSize="s" alignItems="center" justifyContent="spaceBetween" direction="row" wrap responsive={false} >
                <EuiFlexItem grow={false} >
                    <EuiFlexGroup gutterSize="s" alignItems="center" direction="row" wrap responsive={false} >
                        <EuiFlexItem grow={false} >
                            <EuiLink onClick={() => history.push("/")} >
                                <EuiImage url="/logo512.png" alt="logo" style={{ width: 24, height: 24 }} />
                            </EuiLink>
                        </EuiFlexItem>

                        <EuiFlexItem grow={false} >
                            <EuiLink onClick={() => history.push("/")} >djmoberg.com</EuiLink>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false} >
                    <EuiPopover
                        id="trapFocus"
                        ownFocus
                        button={<EuiButtonIcon color="text" iconType="gear" aria-label="settings" onClick={() => setIsPopoverOpen(!isPopoverOpen)} />}
                        isOpen={isPopoverOpen}
                        closePopover={() => setIsPopoverOpen(false)}
                    >
                        <Settings />
                    </EuiPopover>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />

            <EuiHorizontalRule margin="none" />

            {isDesktop && <EuiSpacer />}

            <Sticky>
                <EuiSideNav
                    mobileTitle="Navigate djmoberg.com"
                    toggleOpenOnMobile={toggleOpenOnMobile}
                    isOpenOnMobile={isSideNavOpenOnMobile}
                    items={sideNav}
                />
            </Sticky>
        </Fragment>
    )
}

export default SideNav
